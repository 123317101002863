import { PageContext } from "vike/types";
import type { Store } from "../../+store.server.js";
import CollectionIntro from "../../../components/collection_intro/index.jsx";
import SliderFooter from "../../../components/footer/index.jsx";
import ListBundles from "../../../components/list_bundles/index.jsx";
import ListNarrow from "../../../components/list_narrow/index.jsx";
import { ListOnyx } from "../../../components/list_onyx/index.jsx";
import ListProducts from "../../../components/list_products/index.jsx";
import { Link } from "../../../components/link/index.jsx";

export function SummaryCollection(pageContext: PageContext, handle: string) {
  const { collections, products } = pageContext.store as Store;

  const collection = collections.find((c) => c.handle == handle);

  const List =
    collection.listType == "Onyx list grid"
      ? ListOnyx
      : collection.listType == "Narrow list"
      ? ListNarrow
      : collection.listType == "Bundle list"
      ? ListBundles
      : ListProducts;

  return (
    <>
      <h2 slot="menu">{collection.title}</h2>
      <p slot="menu">{collection.tagLine}</p>
      <Link
        className={`button next`}
        slot="details"
        href={`/${collection.handle}/`}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: (collection.ctaText || "Browse").replace(
              / x /g,
              '<strong class="x"></strong>'
            ),
          }}
        />
      </Link>

      <CollectionIntro collection={collection} />

      <List
        allProducts={products}
        products={collection.productIds.map((id) =>
          products.find((p) => p.id == id)
        )}
        activeId={null}
        loading={collection.handle == handle ? "eager" : "lazy"}
      />
      <SliderFooter sections={collection.sections} slot="footer" />
    </>
  );
}
