import "preact/debug";
// https://vike.dev/onRenderClient
export default onRenderClient;
import "preact/debug";
import { hydrate, render } from "preact";
import { options } from "preact";
import { PageShell } from "./PageShell.jsx";
import { PageContextClient } from "vike/types";
import type { Store } from "../pages/+store.server.js";

var store: Store = null;
async function onRenderClient(context: PageContextClient) {
  store ||= context.store;
  context.store ||= store;
  const { Page, Layout, getDocumentProps } = context.config;
  const page = (
    <PageShell pageContext={context} key="shell">
      <Layout {...context}>
        <Page {...context} />
      </Layout>
    </PageShell>
  );

  const container = document.querySelector("body");
  const documentProps = getDocumentProps(context);

  if (context.isHydration) {
    hydrate(page, container);
  } else {
    console.timeStamp("page render");
    //console.group("page render " + context.urlPathname);
    console.time("page render " + context.urlPathname);
    render(page, container);
    console.timeEnd("page render " + context.urlPathname);
    // console.groupEnd();
    document.title = documentProps.title;
  }
}
